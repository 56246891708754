import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';

export const Hero = ({ backgroundImageUrl, dataTestId = 'hero-section-component' }) => {
  return (
    <div data-e2e="boat-loans-rates-title-hero-section-container" className="boat-loans-rates-hero-section-container" data-testid={dataTestId}>
      <img className="hero-section-image" alt="Boat Loan Rates Hero Image" src={ backgroundImageUrl }></img>
      <div data-e2e="boat-loans-rates-hero-section" className="hero-section">
        <div data-e2e="boat-loans-rates-flex-item-center" className="flex items-center">
          <div data-e2e="boat-loans-rates-content" className="hero-content">
            <h1 data-e2e="boat-loans-rates-title" className="hero-title">
              Boat Loan Rates
            </h1>
            <p className="hero-subtitle">
              Get an instant estimate of your boat loan rate with Boat Trader’s loan rate calculator.
            </p>
            <ApplyNowButton
              text="Get Pre-Qualified"
              type="button"
              utmCampaign="bt-rates-calc-pg"
              utmContent="bt-rates-calc-pg"
              trackingEvent={{
                'action_label': 'hero - boat loan rates'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
};

import {
  isSegmentCandidateForFinance,
  isSegmentCandidateForBrokerDealer,
  isSegmentCandidateForFinanceAdvantage,
} from '../../../utils/trident';

const isSegmentCandidate = (listing) => {
  if ((listing ?? null) === null) {return false;}
  return (
    isSegmentCandidateForFinance(listing) &&
    isSegmentCandidateForBrokerDealer(listing) &&
    isSegmentCandidateForFinanceAdvantage(listing)
  );
};

export const interestedInFinanceCheckboxExperiment = {
  isSegmentCandidate
};

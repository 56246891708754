import React from 'react';
import SRPEmailLeadForm from '../../containers/BoatDetails/BDPSections/SRPEmailLeadForm';

const ListingContactForm = ({listing, onClose, open, defaultValues}) => {
  if (!open) {
    return null;
  }

  return (
    <SRPEmailLeadForm
      listing={listing}
      buttonAttributes={{ 'data-testing-class': 'form-submission-srp'}}
      onClose={onClose}
      defaultValues={defaultValues}
    />
  );
};

export default ListingContactForm;

import React, { useEffect } from 'react';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import { Hero } from './components/Hero';
import RichSnippet from '../../components/RichSnippet';
import DefaultLayout from '../../layouts/Default';
import { TRACKING_BOAT_LOANS_RATES_PAGE_NAME, TRACKING_BOAT_LOANS_RATES_FINANCE_SITE_SECTION } from '../../constants/BoatLoansRates';
import { BOAT_LOANS_RATES_HERO_BACKGROUND, BOAT_LOANS_RATES_URL, CDN_RATES_URL } from '../../constants/BoatLoans';
import { getFAQSchema } from '../../utils/metaContentHelper';
import { RatesCalculatorSection } from './components/RatesCalculatorSection';
import { InfoSection } from './components/InfoSection';
import { FrequentlyQuestionsSection } from './components/FrequentlyQuestionsSection';
import { faq } from './components/FrequentlyQuestionsSection/faqSchema';
import './styles.css';

const BoatLoansRates = (props) => {

  useEffect(() => {
    const trackingEvent = {
      'event': 'pageview',
      'detailed_page_name': TRACKING_BOAT_LOANS_RATES_PAGE_NAME,
      'site_section': TRACKING_BOAT_LOANS_RATES_FINANCE_SITE_SECTION,
    };
    window.dataLayer.push(trackingEvent);
  }, []);

  const backgroundImageUrl = `${ CDN_RATES_URL }${ BOAT_LOANS_RATES_HERO_BACKGROUND }`;
  return (
    <>
      <RichSnippet schema={getFAQSchema(faq)} />
      <DefaultLayout {...props} pageType="BoatLoansRates" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb items={[{
            text: 'Home',
            url: '/'
          }, {
            text: 'Boat Loan Rates',
            url: BOAT_LOANS_RATES_URL,
          }]} />
        </div>

        <div className="main-section">
          <div className="boatloans-rates">
            <Hero backgroundImageUrl={backgroundImageUrl} />
            <RatesCalculatorSection />
            <InfoSection />
            <FrequentlyQuestionsSection />
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default BoatLoansRates;

/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import './styles.css';
import PropTypes from 'prop-types';
import { getApplyNowLink } from '../../../../utils/trident';
import { DEFAULT_BOAT_LOANS_RATES_DATA_LAYER_TRACKING_PARAMS } from '../../../../constants/BoatLoansRates';

export const ApplyNowButton = (props) => {
  const { text, type, size = 'regular', utmCampaign, utmContent, trackingEvent } = props;

  const onClick = () => {
    const trackingEventParams = { ...DEFAULT_BOAT_LOANS_RATES_DATA_LAYER_TRACKING_PARAMS, ...trackingEvent };
    window.dataLayer.push(trackingEventParams);

    const ctaLink = getApplyNowLink({ isVariant: false, utmCampaign, utmContent });
    window.open(ctaLink, '_blank');
  };

  return (
    <a
      className={`apply-now-${type} ${size}`}
      data-testid="apply-now-button"
      onClick={onClick}
    >
      {text}
    </a>
  );
};

ApplyNowButton.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  utmCampaign: PropTypes.string,
  trackingEvent: PropTypes.shape({
    'action_label': PropTypes.string,
    'action_type': PropTypes.string,
    'detailed_page_name': PropTypes.string,
    'portal': PropTypes.string,
    'site_section': PropTypes.string,
    'site_subsection': PropTypes.string,
  })
};

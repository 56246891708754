import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect} from 'react-router-dom';
import get from 'lodash/get';
import BoatsMainContent from './BoatsMainContentV2';
// import BoatsMainContent from './BoatsMainContent';
import DefaultLayout from '../../layouts/Default';
import * as actions from '../../store/actions';
import * as tridentActions from '../../store/actions/tridentApi';
import {bypassingUrl} from '../../store/actions';
import ErrorMessage from '../../components/ErrorMessage';
import * as utils from '../../store/utils';
import { withCookies} from 'react-cookie';
import {
  generateSearchPath
} from '../../utils/urlHelpers/listings';
import {getParamsFromLocationAndHistoryQueryString} from '../../utils/featureToggles';
import {REDUCTION_REDIRECT_COOKIE} from '../../constants/boatDetails';
import {runOnce} from '../../utils/runOnceHelper';
import {withABTest} from '../../server/abTest/withABTest';
import {frontloadConnect} from 'react-frontload';
import {
  doubleHyphenRedirect,
  getUrlRedirect,
  locationRedirectUrl, typeClassRedirectUrl,
  useBoatsIndexProps, validMakeRedirectUrl
} from '../../utils/parsers/boatIndexParser';

const setRedirectionCookie = (redirect, cookies) => {
  if (redirect) {
    cookies.set(REDUCTION_REDIRECT_COOKIE, true);
  }
};

const SearchResults = (props) => {
  const parsedProps = useBoatsIndexProps(props);

  const {records, cookiesRedirectOnServer, cookies, pathName,
    isNotRoot, emptyWrongPage, urlMatchParams, zipGeoData,
    partyDetails, dealerId, isBranded, bypassUrl, canBeRedirected,
    pageType, searchPage, noAdsParam} = parsedProps;

  setRedirectionCookie(cookiesRedirectOnServer, cookies);

  const hyphensRedirect = useMemo(() => {
    return doubleHyphenRedirect(pathName, isNotRoot);
  }, [pathName, isNotRoot]);

  const locationMakeOrClassRedirect = useMemo(() => {
    return locationRedirectUrl(urlMatchParams, bypassUrl, zipGeoData, canBeRedirected) ||
      validMakeRedirectUrl(urlMatchParams, records) ||
      typeClassRedirectUrl(urlMatchParams);
  }, [urlMatchParams, records]); // eslint-disable-line react-hooks/exhaustive-deps

  const rootSRPRedirect = useMemo(() => {
    const modelWithoutMake = !isBranded && urlMatchParams?.model && !urlMatchParams?.make;
    const disabledId = dealerId && partyDetails?.statusId === 'DSBLD';
    let srpRedirect = '';
    if (modelWithoutMake || disabledId) {
      srpRedirect = generateSearchPath(undefined, undefined, true);
    }
    return srpRedirect;
  }, [urlMatchParams, isBranded, dealerId, partyDetails]);

  if (hyphensRedirect) {
    return <Redirect to={hyphensRedirect} {...props} />;
  }

  if (emptyWrongPage) {
    return <Redirect to={{pathname: getUrlRedirect(props)}} {...props} />;
  }

  if (locationMakeOrClassRedirect) {
    return <Redirect to={{pathname: locationMakeOrClassRedirect}} {...props} />;
  }

  if (rootSRPRedirect) {
    return <Redirect to={{pathname: rootSRPRedirect}} {...props} />;
  }

  return (
    <DefaultLayout {...props} pageType={pageType} noAdsParam={noAdsParam}>
      {props.errors ? (
        <ErrorMessage {...props} />
      ) : (
        <BoatsMainContent {...props} noAdsParam={noAdsParam} searchPage={ searchPage } />
      )}
    </DefaultLayout>
  );
};

const processTridentLoanSpecs = (state) => {
  const teaserRate = get(state.app, 'trident.rates[0].teaserRate');

  return {
    tridentTeaserRate: parseFloat(teaserRate?.replace(/[%, ]/g, '')),
  };
};

const mapStateToProps = (state) => {
  const {
    tridentTeaserRate
  } = processTridentLoanSpecs(state);

  return {
    params: get(state.app, 'params', {}),
    models: get(state.app, 'models', {}),
    search: get(state.app, 'data.search', {}),
    sponsored: get(state.app, 'data.sponsored', {}),
    carouselSponsored: get(state.app, 'data.carouselSponsored', {}),
    facets: get(state.app, 'data.facets', {}),
    seoMakeInfo: get(state.app, 'data.seoMakeInfo', null),
    interestingInformation: get(state.app, 'data.interestingInformation', {}),
    similarBoatsForOEMs: get(state.app, 'data.similarBoatsForOEMs', false),
    isWorking: state.app.isWorking,
    componentWorking: state.app.componentWorking,
    success: state.app.success,
    errors: state.app.errors,
    message: state.app.message,
    statusCode: state.app.statusCode,
    user: state.app.user,
    myboats: state.app.myboats,
    makeModel: get(state.app, 'data.makeModel'),
    wordsmithContent: get(state.app, 'data.wordsmithContent'),
    userCountryCode: state.app.userCountryCode,
    userCountryCodeError: state.app.userCountryCodeError,
    oemDetails: get(state.app, 'data.oemDetails', {}),
    oemList: get(state.app, 'data.manufacturer', {count: 0, records: []}),
    partyDetails: get(state.app, 'data.partyDetails', {}),
    videos: get(state.app, 'data.videos', []),
    relatedBoatArticles: get(state.app, 'data.relatedBoatArticles'),
    secondaryLocalities: get(state.app, 'data.secondaryLocalities'),
    zipGeoData: get(state.app, 'data.zipGeoData'),
    bypassUrl: get(state.app, 'bypassUrl'),
    tridentTeaserRate,
    ficoScores: tridentActions.getFicoScores(get(state.app, 'trident.rates[0].rates'), []),
    isLeadSubmitted: get(state.app, 'trident.isLeadSubmitted', false),
    brandDetails: get(state.app, 'data.brandDetails', {}),
    serverAds: get(state.app, 'data.serverAds', []),
  };
};

// we create a function that will run only once bypassing multiple renders.
const justFirsTime = runOnce();
const frontLoadConnectAction = ({dispatch, history, location, cookies, abTestConfiguration, bypassUrl, debug = process.env.REACT_APP_LOCAL_DEBUG}) => {
  const otherParams = getParamsFromLocationAndHistoryQueryString({
    location,
    history,
  });

  if (utils.isServer() || actions.shouldGetData(location)) {
    // This allows us to avoid a re-call when we want to make a redirection without fetching data again
    if (bypassUrl) {
      return dispatch(bypassingUrl(null));
    }

    return Promise.allSettled([
      dispatch(actions.getData(location.pathname, cookies?.cookies, otherParams, abTestConfiguration)),
      dispatch(tridentActions.getRatesFromTridentAPI())
    ]);

  }

  // If we want to debug react properly, with live reload and proper component inspection
  // we can dispatch here, and we have to run it only one time.
  // We should declare the REACT_APP_LOCAL_DEBUG locally (on the .env file) to debug
  if (justFirsTime() && debug) {
    return dispatch(actions.getData(location.pathname, cookies?.cookies, otherParams, abTestConfiguration));
  }
};

const frontLoadConected = frontloadConnect(frontLoadConnectAction, { onUpdate: true, onMount: true,});

// eslint-disable-next-line react/display-name
const SearchResultsWrap = (Comp) => (props) => {
  const stateProps = useSelector(mapStateToProps); // eslint-disable-line react-hooks/rules-of-hooks
  const dispatch = useDispatch(); // eslint-disable-line react-hooks/rules-of-hooks
  const newProps = {...props, ...stateProps, cookies: props.cookies, dispatch};
  return <Comp {...newProps} />;
};

export default withABTest(
  withCookies(
    SearchResultsWrap(
      frontLoadConected(SearchResults)
    )
  )
);

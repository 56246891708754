import { User } from '@dmm/lib-react-userflow-components';
import '@dmm/lib-react-userflow-components/dist/cjs/index.css';
import get from 'lodash/get';
import React, { PureComponent, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUser, getUserEmail, likeQueuedBoats, setUserRef } from '../../store/actions';
import { setGenericEvent } from '../../store/actions/dataLayer';
import { BRANCH_EVENT, branchIoEventsManager } from '../../utils/branchIoEventsManager';
import { buyerAccountLoginExperiment } from '../../utils/fsbo/experiments';
import { withABTest } from '../../server/abTest/withABTest';
import './styles.css';

class Header extends PureComponent {

  state = {
    open: false,
    showHeader: true,
    accordians: {
      find: '',
      financing: '',
      services: '',
      resources: '',
      sell: ''
    },
    userRef: createRef()
  }

  scrollPos = 0;

  authConfig = {
    region: process.env.REACT_APP_COGNITO_CONFIG_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CONFIG_USER_POOL_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_REDIRECT_SIGNIN,
      redirectSignOut: process.env.REACT_APP_COGNITO_CONFIG_OAUTH_REDIRECT_SIGNIN
    }
  }

  signinFormPropsPrivateSellerBDP  = {
    text: {
      username: 'Email',
      password: 'Password',
      usernamePlaceholder: 'Enter Email',
      passwordPlaceholder: 'Enter Password',
      signinMessage: <p><b>Sign in to message Private Sellers.</b> Join the Boat Trader community of boating enthusiasts. No bots. Just real boat buyers and sellers. </p>,
      signinButtonText: 'Sign In',
      forgotPasswordLink: 'Forgot Password?'
    }
  }

  signupFormPropsPrivateSellerBDP = {
    text: {
      email: 'Email',
      password: 'Password',
      emailPlaceholder: 'Enter Email',
      passwordPlaceholder: 'Create Password',
      signupMessage: <div><b>Sign up to message Private Sellers.</b> Join the Boat Trader community of boating enthusiasts. No bots. Just real boat buyers and sellers.</div>,
      signupButton: 'Submit'
    }
  }

  handleHeader() {
    let windowPos = document.documentElement.scrollTop || window.pageYOffset;

    if (windowPos < this.scrollPos) {
      if (!this.state.showHeader) {
        this.updateHeader(true);
      }
    } else if (windowPos > 50) {
      if (this.state.showHeader) {
        this.updateHeader(false);
      }
    }

    this.scrollPos = windowPos;
  }

  updateHeader(sticky) {
    const leaderBoard = typeof this.props.getLeaderBoard === 'function' ? this.props.getLeaderBoard() : document.body.querySelector('#div-gpt-mobile-leaderboard-custom');
    if (leaderBoard) {
      leaderBoard.classList[sticky ? 'add' : 'remove']('header-present');
    }
    this.setState({showHeader: sticky});
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleHeader.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleHeader.bind(this));
  }

  onUserInit = () => {
    const pathname = get(this.props, 'location.pathname', '');
    if (!pathname.includes('/myboats/')) {
      this.props.getUser();
      this.props.getUserEmail();
    } else {
      this.props.likeQueuedBoats();
    }

    this.props.setUserRef(this.state.userRef);
  }

  onUserSignIn = () => {
    branchIoEventsManager(BRANCH_EVENT.LOGIN);
    this.props.getUser();
    this.props.getUserEmail();
  }

  onCreateAccountSuccess = () => {
    branchIoEventsManager(BRANCH_EVENT.REGISTER);
  }

  onUserSignOut = () => {
    const { pathname } = this.props.location;
    if (pathname.includes('/myboats/')) {
      window.location.href = window.location.origin + process.env.REACT_APP_LOGOUT_REDIRECT_PAGE;
    } else {
      this.props.getUser();
      this.props.getUserEmail();
    }
  }

  handleClick = (e) => {
    if (e.target && e.target.dataset && e.target.dataset.action && e.target.dataset.label) {
      this.props.setGenericEvent('navigation - header', e.target.dataset.action, e.target.dataset.label, '', e.target.dataset.custom);
    }
  }

  render() {
    let { open, showHeader, accordians, userRef } = this.state;
    const { hideAuth, abTestConfiguration, listing } = this.props;
    //Explicitly setting undefined whereas this was the default prior to having different options
    let signInProps = undefined;
    let signUpProps = undefined;
    if (listing && abTestConfiguration && buyerAccountLoginExperiment.isActive(abTestConfiguration, listing)) {
      signInProps = this.signinFormPropsPrivateSellerBDP;
      signUpProps = this.signupFormPropsPrivateSellerBDP;
    }

    const tracking = (action, label, custom) => ({
      'data-action': action,
      'data-label': label,
      'data-custom': custom
    });

    return (
      <div className={`page-header bt-ana-1099 ${open ? 'nav-open' : ''} ${showHeader ? 'show-header' : 'hide-header'}`}>
        <span className="nav-cover" onClick={() => this.toggleOpen()}></span>
        <nav id="main-nav" data-e2e="main-nav-container" onClick={this.handleClick.bind(this)}>
          {!hideAuth && <div data-e2e="authentication" className="authentication">
            <User
              ref={userRef}
              cognitoConfig={this.authConfig}
              userMenuProps={{menuItems: [
                {href: '/myboats/tab/savedBoats/', label: 'Boats I Like'},
                {href: '/myboats/tab/boatsForSale/', label: 'My Boats For Sale'},
                {href: '/myboats/tab/profile/', label: 'My Profile'}
              ],
              iconUrl: `${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/user-icon.svg`}}
              onInit={this.onUserInit}
              onSignInSuccess={this.onUserSignIn}
              onCreateAccountSuccess={this.onCreateAccountSuccess}
              onSignOut={this.onUserSignOut}
              title={{main: 'Welcome to Boat Trader'}}
              signinFormProps={signInProps}
              signupFormProps={signUpProps}
            />
          </div>}
          <ul id={this.props.id}>
            <li className="nav nav-right visible-xs sell"><a href="/sell/" {...tracking('sell your boat', 'sell')}>Sell Your Boat</a> </li>
            <li data-e2e="nav-left-find" className="nav nav-left find">
              <a href="/boats/" data-e2e="find-dropdown" className="selectdiv trackFind hidden-xs">Find</a>
              <div className={`selectdiv visible-xs ${accordians.find}`} onClick={() => this.toggleAccordian('find')}>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/dropdown-arrow.svg`} alt="droparrow icon" width="12" height="8" />
                Find
              </div>
              <ul data-e2e="dropList-findList" className="dropList findList">
                <li><a href="/boats/" title="Boats For Sale" {...tracking('find', 'search boats')}>Boats For Sale</a></li>
                <li><a href="/boat-types/" className="trackType" title="Boat Types" {...tracking('find', 'browse types')}>Boat Types</a> </li>
                <li><a href="/boat-dealers/" className="trackDealer" title="Boat Dealers" {...tracking('find', 'search dealers')}>Boat Dealers</a> </li>
                <li><a href="/engines/" className="trackEngine" title="Outboard Motors &amp; Engines" {...tracking('find', 'search engines')}>Outboard Motors &amp; Engines</a> </li>
                <li><a href="/browse/parts-trailers/" className="trackTrailer" title="Boat Trailers" {...tracking('find', 'search trailers')}>Boat Trailers</a> </li>
              </ul>
            </li>
            <li data-e2e="nav-left-selling" className="nav nav-left sell-accordian hidden-xs">
              <a data-e2e="sell-your-boat" href="/sell/" title="Sell Your Boat" {...tracking('sell your boat', 'sell')}>Sell Your Boat</a>
            </li>
            <li data-e2e="nav-left-financing" className="nav nav-left financing">
              <a role="button" data-e2e="selectdiv-finance" className="selectdiv hidden-xs">Finance</a>
              <div className={`selectdiv visible-xs ${accordians.financing}`} onClick={() => this.toggleAccordian('financing')}>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/dropdown-arrow.svg`} alt="droparrow icon" width="12" height="8" />
                Finance
              </div>
              <ul data-e2e="dropList-finance" className="dropList">
                <li><a data-e2e="boat-loans-finance" href="/boat-loans/" title="Boat Loans" {...tracking('financing', 'financing')}>Boat Loans</a></li>
                <li><a data-e2e="calculate-your-payments-finance" href="/boat-loans/calculator/" title="Boat Loan Calculator" {...tracking('financing', 'calculate your payments')}>Boat Loan Calculator</a></li>
                <li><a data-e2e="boat-loans-rates" href="/boat-loans/rates/" title="Boat Loan Rates" {...tracking('financing', 'rates')}>Boat Loan Rates</a></li>
                <li><a data-e2e="faq-how-to-finance" href="/boat-loans/#boat-financing" title="FAQ - How to Financing" {...tracking('financing', 'faq - how to finance')}>FAQ - How to Finance</a></li>
              </ul>
            </li>
            <li data-e2e="nav-left-services" className="nav nav-left services">
              <a role="button" data-e2e="selectdiv-services" className="selectdiv hidden-xs">Services</a>
              <div className={`selectdiv visible-xs ${accordians.services}`} onClick={() => this.toggleAccordian('services')}>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/dropdown-arrow.svg`} alt="droparrow icon" width="12" height="8" />
                Services
              </div>
              <ul data-e2e="droplist-services" className="dropList">
                <li><a data-e2e="extended-service-plan--services" href="/services/extended-service-plan/" title="Extended Service Plan" {...tracking('services', 'extended service plan')}>Extended Service Plan</a></li>
                <li><a data-e2e="tire-wheel-plan-services" href="/services/tire-wheel-protection/" title="Tire & Wheel Plan" {...tracking('services', 'tire wheel protection')}>Tire & Wheel Plan</a></li>
                <li><a data-e2e="gap-protection-services" href="/services/gap-protection/" title="GAP Protection" {...tracking('services', 'gap protection')}>GAP Protection</a></li>
                <li><a data-e2e="roadside-assistance-services" href="/services/roadside-assistance/" title="Roadside Assistance" {...tracking('services', 'roadside assistance')}>Roadside Assistance</a></li>
                <li><a data-e2e="boat-insurance-services" href="/services/boat-insurance/" title="Boat Insurance" {...tracking('services', 'insurance')}>Boat Insurance</a></li>
                <li><a data-e2e="boat-transport-services" href="/services/boat-transport-shipping/" title="Boat Transport" {...tracking('services', 'transport')}>Boat Transport</a></li>
                <li><a data-e2e="boat-warranty-services" href="/services/boat-warranty/" title="Boat Warranty" {...tracking('services', 'warranty')}>Boat Warranty</a></li>
                <li><a data-e2e="boat-documentation-services" href="/services/boat-documentation/" title="Boat Documentation" {...tracking('services', 'documentation')}>Boat Documentation</a></li>
                <li><a data-e2e="boat-rental-services" href="/services/boat-rental/" title="Boat Rental" {...tracking('services', 'Rental')}>Boat Rental</a></li>
                <li><a data-e2e="become-a-member" target="_blank" rel="noreferrer noopener" href="https://www.boatsgroup.com/our-brands/boat-trader/" title="Become a Member" {...tracking('services', 'become a member')}>Become a Member</a></li>
              </ul>
            </li>
            <li data-e2e="nav-left-research" className="nav nav-left research">
              <a data-e2e="selectdiv-research" href={`${process.env.REACT_APP_BLOG_URL}`} className="selectdiv hidden-xs" {...tracking('resources', 'resources')}>Research</a>
              <div className={`selectdiv visible-xs ${accordians.resources} non-expandable`}>
                <img src={`${process.env.REACT_APP_CDN_URL}/img/tol-design/btol/bt-inc-release/dropdown-arrow.svg`} onClick={() => this.toggleAccordian('resources')} alt="droparrow icon" width="12" height="8" />
                <a href={`${process.env.REACT_APP_BLOG_URL}`} {...tracking('resources', 'resources')}>Research</a>
              </div>
              <ul data-e2e="droplist-research" className="dropList researchList">
                <li className="titles"><span>Get Advice On:</span> </li>
                <li><a data-e2e="buying-research" className="adviceBuying droplist-indent" href={`${process.env.REACT_APP_BLOG_URL}buying/`} title="Buying" {...tracking('resources', 'buying advice')}>Buying</a> </li>
                <li><a data-e2e="selling-research" href={`${process.env.REACT_APP_BLOG_URL}selling/`} className="adviceSelling droplist-indent" title="Selling" {...tracking('resources', 'selling advice')}>Selling</a> </li>
                <li><a data-e2e="boating-research" href={`${process.env.REACT_APP_BLOG_URL}boating/`} className="adviceBoating droplist-indent" title="Boating" {...tracking('resources', 'boating advice')}>Boating</a> </li>
                <li className="titles"><span>Read</span> </li>
                <li><a data-e2e="reviews-research" href={`${process.env.REACT_APP_BLOG_URL}reviews/`} title="Reviews" className="readReviews droplist-indent" {...tracking('resources', 'reviews')}>Reviews</a> </li>
              </ul>
            </li>
          </ul>
        </nav>
        <header data-e2e="main-nav-slide-header" className={`main nav-slide ${showHeader ? 'show-header' : 'hide-header'}`}>
          <button data-e2e="navbar-toggle-btn" onClick={() => this.toggleOpen()} className="navbar-toggle" data-target=".navbar-ex1-collapse" data-toggle="collapse" type="button">
            <span className="visuallyhidden">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a data-e2e="header-logo-link" href="/" aria-label="logo">
            <svg className="logo" width="150" height="19" viewBox="0 0 150 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M42.3638 12.6419C41.098 12.9544 40.6292 13.1263 40.2385 13.4076C39.7697 13.767 39.551 14.1733 39.551 14.7358C39.551 15.6422 40.301 16.2985 41.348 16.2985C42.3169 16.2985 43.4264 15.8141 44.0202 15.1421C44.5046 14.5796 44.7703 13.767 44.7703 12.8294C44.7703 12.595 44.7546 12.1418 44.7234 11.7355C43.8014 12.1887 43.3639 12.3606 42.3638 12.6419ZM51.4428 17.1423C51.2866 17.3924 51.0834 17.6268 50.8021 17.8612C50.0364 18.5487 49.2082 18.6112 48.255 18.58C46.5829 18.5487 45.4891 17.8455 44.8953 16.2829C44.1452 16.9861 43.7389 17.3142 43.1607 17.6111C42.1137 18.2206 40.7855 18.5487 39.5822 18.5487C37.2851 18.5487 35.4412 16.9392 35.4412 14.9702C35.4412 13.1732 36.5194 12.1262 39.0197 11.5011C39.926 11.2667 40.1292 11.2199 42.6607 10.8448C44.0983 10.626 44.7546 10.1572 44.7546 9.39154C44.7546 8.36018 43.6608 7.65698 42.0981 7.65698C41.098 7.65698 40.2698 7.8445 39.2541 8.34455C39.5978 8.73522 39.7385 9.07901 39.7385 9.4853C39.7385 10.3135 39.0353 10.8917 38.0821 10.8917C37.082 10.8917 36.3475 10.2041 36.3475 9.26653C36.3475 7.42259 38.5509 6.26621 42.2075 6.26621C44.364 6.26621 46.0829 6.6725 47.0049 7.40696C47.9268 8.11015 48.2394 9.0165 48.2394 10.9542V14.1889C48.255 15.4547 48.255 15.4547 48.255 15.7359C48.3019 16.3454 48.6144 16.7829 49.3332 16.8142C50.3333 16.8611 50.8178 16.0172 50.9428 15.0015V14.7983V8.34455H48.9269V6.68813C51.2397 6.56312 52.9117 5.00046 52.8961 2.96899H54.4588V6.68813H57.631V8.34455H54.4588V14.9546C54.4588 15.6578 54.4588 15.7516 54.5682 16.0485C54.6932 16.4391 55.0057 16.6892 55.3964 16.6892C56.0058 16.6892 56.2246 16.2829 56.2246 15.2046V13.5951H57.7872V15.3453C57.7872 17.3767 56.5527 18.58 54.4119 18.58C53.1774 18.58 52.1773 18.1737 51.6147 17.4236C51.5678 17.3299 51.5053 17.2361 51.4428 17.1423Z" fill="#0F4D77"/>
              <path d="M14.064 0.0312552C16.5173 0.0312552 17.9706 0.359414 19.127 1.12512C20.3146 1.98458 21.0334 3.29722 21.0334 4.71924C21.0334 5.90687 20.5021 6.93822 19.5489 7.67268C18.9082 8.14147 18.3613 8.3915 17.1893 8.6259C20.3146 9.48537 21.8929 11.0324 21.8929 13.2983C21.8929 14.9234 20.9866 16.4861 19.4864 17.3768C18.3457 18.0644 17.0018 18.33 14.814 18.33H0V16.4705L0.640691 16.4392C2.67215 16.3611 3.54724 15.361 3.54724 13.2201V5.10991C3.54724 2.93781 2.67215 1.98458 0.640691 1.89082L0 1.85957V0H14.064V0.0312552ZM12.8295 7.84457C14.189 7.84457 14.8609 7.62579 15.5172 7.01636C16.0642 6.48505 16.3767 5.78186 16.3767 4.96927C16.3767 3.92229 15.8767 2.98469 15.0172 2.43776C14.3452 2.00021 13.7983 1.89082 12.345 1.89082H7.9852V7.8602H12.8295V7.84457ZM13.4389 16.4861C15.4704 16.4861 16.9861 15.0328 16.9861 13.0951C16.9861 11.9075 16.4392 10.8761 15.5016 10.3292C14.9078 9.95416 14.064 9.78227 12.8763 9.78227H7.9852V16.4861H13.4389Z" fill="#0F4D77"/>
              <path d="M36.1131 12.5795C36.1131 14.3297 35.0193 16.2205 33.3472 17.33C32.144 18.0957 30.5032 18.5645 28.7374 18.5645C24.4557 18.5645 21.2679 15.908 21.2679 12.392C21.2679 10.5324 22.2367 8.75099 23.8931 7.59462C25.0964 6.76641 26.8309 6.28198 28.7061 6.28198C32.9409 6.29761 36.1131 8.96977 36.1131 12.5795ZM25.3308 12.4701C25.3308 15.1423 26.7215 16.9862 28.6905 16.9862C29.7219 16.9862 30.6595 16.5174 31.1908 15.7517C31.7533 14.9235 32.0971 13.6577 32.0971 12.4545C32.0971 9.73546 30.7063 7.82902 28.7218 7.82902C26.7372 7.81339 25.3308 9.73547 25.3308 12.4701Z" fill="#0F4D77"/>
              <path d="M84.1806 6.90697H82.0554C82.0241 5.26617 81.7272 4.28169 80.9459 3.37535C79.9927 2.31274 78.8363 1.87519 76.8517 1.87519H75.4297V12.8763C75.4297 14.3921 75.5704 15.0016 76.0704 15.5329C76.5705 16.0485 77.3049 16.3142 78.2738 16.3611L79.1489 16.3923V18.2363H67.5852V16.3923L68.2415 16.3611C70.2573 16.2829 71.1324 15.3141 71.1324 13.1576V1.87519H69.2103C67.1632 1.87519 65.9131 2.53151 65.0849 3.98478C64.6786 4.71924 64.4911 5.48494 64.3504 6.90697H62.2408V0.03125H84.1806V6.90697Z" fill="#0F4D77"/>
              <path d="M87.7435 9.20416C89.0562 7.17269 90.4001 6.29761 92.1815 6.29761C93.8379 6.29761 95.0412 7.42272 95.0412 8.95413C95.0412 10.0949 94.2442 10.9387 93.0722 10.9387C92.119 10.9387 91.4783 10.3605 91.4783 9.46981C91.4783 9.29792 91.4939 9.14165 91.6033 8.86037C90.7438 8.86037 90.4 8.92288 89.7906 9.29792C88.4936 10.048 87.931 11.2981 87.931 13.3765V14.2828C87.931 16.0486 88.3998 16.6112 89.8687 16.6112H90.3375V18.2676H82.1179V16.6112H82.4773C83.9462 16.6112 84.4151 16.0486 84.4151 14.2828V10.7043C84.4151 8.95413 83.9462 8.37595 82.4773 8.37595H82.0085V6.71952H87.7279L87.7435 9.20416Z" fill="#0F4D77"/>
              <path d="M102.995 11.7513C103.011 12.1576 103.042 12.6108 103.042 12.8452C103.042 13.7984 102.761 14.611 102.292 15.1735C101.682 15.8611 100.573 16.3612 99.6041 16.3612C98.5415 16.3612 97.7914 15.6892 97.7914 14.7829C97.7914 14.2203 98.0102 13.814 98.479 13.4233C98.8697 13.1264 99.3385 12.9545 100.62 12.642C101.636 12.3764 102.058 12.2045 102.995 11.7513ZM108.339 13.5171V15.0954C108.339 16.2205 108.043 16.7362 107.417 16.7362C106.917 16.7362 106.667 16.408 106.62 15.8142C106.62 15.5329 106.62 15.5329 106.605 14.2672V11.0325C106.605 9.06352 106.277 8.1728 105.355 7.45398C104.433 6.71953 102.698 6.31323 100.526 6.31323C96.8538 6.31323 94.6192 7.45398 94.6192 9.32917C94.6192 10.2824 95.3537 10.97 96.3694 10.97C97.3226 10.97 98.0258 10.3918 98.0258 9.54795C98.0258 9.14165 97.8696 8.79787 97.5258 8.4072C98.5571 7.90715 99.3854 7.71963 100.385 7.71963C101.964 7.71963 103.058 8.43846 103.058 9.46981C103.058 10.2511 102.386 10.7199 100.948 10.9387C98.4009 11.3294 98.1821 11.345 97.2758 11.6107C94.7599 12.2514 93.6816 13.2983 93.6816 15.1267C93.6816 17.1112 95.5412 18.7208 97.8383 18.7208C99.0416 18.7208 100.385 18.377 101.432 17.7675C101.995 17.4394 102.401 17.1269 103.183 16.408C103.761 17.9863 104.87 18.7208 106.574 18.7208C107.699 18.7208 108.605 18.3926 109.199 17.7988C109.699 17.2988 109.871 16.7362 109.871 15.7517V13.5952L108.339 13.5171Z" fill="#0F4D77"/>
              <path d="M114.809 8.26653C116.637 8.26653 117.747 9.95421 117.747 12.767C117.747 15.1579 116.606 16.7518 114.887 16.7518C113.09 16.7518 111.871 14.9547 111.871 12.3295C111.887 9.95421 113.074 8.26653 114.809 8.26653ZM121.153 1.89087H115.153V3.54729H115.684C117.122 3.54729 117.622 4.12548 117.622 5.87566V8.39155C116.434 6.98515 115.121 6.36009 113.465 6.36009C110.277 6.36009 107.855 8.95411 107.855 12.3607C107.855 13.9546 108.418 15.5798 109.371 16.7362C110.324 17.9238 111.762 18.5645 113.465 18.5645C115.325 18.5645 116.59 17.955 117.997 16.408V18.2207H123.669V16.5643H123.138C121.669 16.5643 121.169 15.9861 121.169 14.2359V1.89087H121.153Z" fill="#0F4D77"/>
              <path d="M126.419 11.5482C126.544 9.23541 127.56 7.95403 129.248 7.95403C130.154 7.95403 130.889 8.36033 131.389 9.15728C131.795 9.79798 131.951 10.3918 131.967 11.5482H126.419ZM133.858 14.5328C133.342 16.0017 132.108 16.8456 130.451 16.8456C129.138 16.8456 127.904 16.2674 127.216 15.2517C126.857 14.6891 126.685 14.1734 126.576 13.2827H135.936C135.92 11.2356 135.702 10.2199 134.92 9.09478C133.78 7.29772 131.623 6.26636 129.123 6.26636C127.201 6.26636 125.451 6.8758 124.294 7.98529C123.169 9.06352 122.435 10.7825 122.435 12.3607C122.435 14.1266 123.294 15.9392 124.638 17.08C125.826 18.0957 127.482 18.627 129.295 18.627C131.264 18.627 132.967 18.0176 134.17 16.8612C134.842 16.2205 135.186 15.6736 135.686 14.5172H133.842V14.5328H133.858Z" fill="#0F4D77"/>
              <path d="M140.858 9.20416C142.171 7.17269 143.515 6.29761 145.296 6.29761C146.953 6.29761 148.156 7.42272 148.156 8.95413C148.156 10.0949 147.359 10.9387 146.187 10.9387C145.234 10.9387 144.593 10.3605 144.593 9.46981C144.593 9.29792 144.609 9.14165 144.718 8.86037C143.859 8.86037 143.515 8.92288 142.905 9.29792C141.608 10.048 141.046 11.2981 141.046 13.3765V14.2828C141.046 16.0486 141.515 16.6112 142.984 16.6112H143.452V18.2676H135.233V16.6112H135.592C137.061 16.6112 137.53 16.0486 137.53 14.2828V10.7043C137.53 8.95413 137.061 8.37595 135.592 8.37595H135.123V6.71952H140.843L140.858 9.20416Z" fill="#0F4D77"/>
              <path d="M149.766 7.01646C149.766 7.48526 149.453 7.79779 149.062 7.79779C148.672 7.79779 148.359 7.46963 148.359 7.01646C148.359 6.54766 148.672 6.23513 149.062 6.23513C149.437 6.25075 149.766 6.57891 149.766 7.01646ZM148.891 7.11022H149.031L149.266 7.59464H149.484L149.234 7.11022C149.375 7.09459 149.484 7.03209 149.484 6.81331C149.484 6.57891 149.344 6.48515 149.078 6.48515H148.687V7.59464H148.891V7.11022ZM148.891 6.64142H149.062C149.219 6.64142 149.266 6.67267 149.266 6.78206C149.266 6.89144 149.219 6.93832 149.062 6.93832H148.891V6.64142ZM149.047 8.03219C149.562 8.03219 150 7.64152 150 7.03209C150 6.42265 149.562 6.03198 149.047 6.03198C148.547 6.03198 148.094 6.42265 148.094 7.03209C148.094 7.64152 148.547 8.03219 149.047 8.03219Z" fill="#0F4D77"/>
            </svg>
          </a>
        </header>
        {!hideAuth && <div id="modal-root"/>}
      </div>
    );
  }

  toggleOpen(){
    document.body.classList.toggle('nav-open');
    this.setState({
      open: !this.state.open
    });
  }

  toggleAccordian(element){
    this.setState({
      accordians: {
        ...this.state.accordians,
        [element]: this.state.accordians[element].length ? '' : 'closed'
      }
    });
  }
}

export default withABTest(connect(null, dispatch => bindActionCreators({setGenericEvent, getUser, getUserEmail, setUserRef, likeQueuedBoats}, dispatch))(Header));

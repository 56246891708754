import { Ad, NativeAd } from '@dmm/lib-react-ui-components';
import React from 'react';

const mapImageAd = (ad) => {
  return {
    imageUrl: ad.image_url,
    altText: ad.alt_text,
    redirectUrl: ad.redirect_url,
    eligibleUrl: ad.eligible_url,
    viewableUrl: ad.viewable_url,
    htmlBody: ad.body,
    target: ad.target,
    maxHeight: ad.height,
    maxWidth: ad.width
  };
};

const ServerAd = ({ad, className, adName, wrapperExtra}) => {
  let adData = {};
  if (!ad?.placement_id) {
    return <></>;
  }

  adData = mapImageAd(ad);
  const adJsx = (
    <>
      {adData.htmlBody ?
        <NativeAd {...adData}/> :
        <Ad {...adData} maxWidth={ad.width} maxHeight={ad.height} className={className} />
      }
    </>
  );
  const wrappedAds = ['boat-search_mobile-leaderboard-custom'];

  if (wrappedAds.includes(adName)) {
    return (
      <div className={wrapperExtra?.classes} id={wrapperExtra?.adId}>
        <div>
          {adJsx}
        </div>
      </div>
    );
  }

  return adJsx;
};

export default ServerAd;

export const TRACKING_BOAT_LOANS_RATES_CLICK_ACTION_TYPE = 'click';
export const TRACKING_BOAT_LOANS_RATES_CLICK_EVENT = 'link_click';
export const TRACKING_BOAT_LOANS_RATES_FINANCE_SITE_SECTION = 'finance';
export const TRACKING_BOAT_LOANS_RATES_PAGE_NAME = 'boat loan rates';
export const TRACKING_BOAT_LOANS_RATES_PORTAL = 'boat trader';
export const TRACKING_BOAT_LOANS_RATES_SITE_SUB_SECTION = 'boat loan rates';
export const TRACKING_BOAT_LOANS_RATES_PRIMARY_CATEGORY = 'finance';
export const TRACKING_BOAT_LOANS_RATES_CATEGORY_SUB_SECTION = 'boat loan rates';


export const DEFAULT_BOAT_LOANS_RATES_DATA_LAYER_TRACKING_PARAMS = {
  'event': TRACKING_BOAT_LOANS_RATES_CLICK_EVENT,
  'action_type': TRACKING_BOAT_LOANS_RATES_CLICK_ACTION_TYPE,
  'ga4': {
    page: {
      'detailed_page_name': TRACKING_BOAT_LOANS_RATES_PAGE_NAME,
      'section': TRACKING_BOAT_LOANS_RATES_FINANCE_SITE_SECTION,
      'subsection': TRACKING_BOAT_LOANS_RATES_SITE_SUB_SECTION,
      portal: TRACKING_BOAT_LOANS_RATES_PORTAL,
      pageInfo: {
        pageName: TRACKING_BOAT_LOANS_RATES_PAGE_NAME,
      },
      category: {
        primaryCategory: TRACKING_BOAT_LOANS_RATES_PRIMARY_CATEGORY,
        subSection: TRACKING_BOAT_LOANS_RATES_CATEGORY_SUB_SECTION
      }
    }
  }
};

import React, { PureComponent } from 'react';
import CarouselDisplayer from '../CarouselDisplayer';
import DetailsContact from '../../containers/BoatDetails/DetailsContact';
import { MediaCarouselModal } from '@dmm/lib-react-ui-components';
import classnames from 'classnames';
import './styles.css';


const Modal = ({ handleClose, show, children, device, showThumbs, setWasOpened }) => {
  const handleCloseModal = () => {
    setWasOpened(false);
    handleClose();
  };

  return (
    <MediaCarouselModal
      onAnimationEnd={ () => setWasOpened(true) }
      open={ show }
      onClose={ handleCloseModal }
      showCloseText={ device === 'desktop' }
    >
      <div className={classnames({ 'modal display-block modal-carousel': show }, { 'modal display-none': !show }, { 'showThumbs': showThumbs })}>
        <section className="modal-main-carousel">
          {children}
        </section>
      </div>
    </MediaCarouselModal>
  );
};


class CarouselModal extends PureComponent {
  state = {
    wasOpened: false
  }

  componentDidMount() {
    this.setState({});
  }

  setWasOpened = (wasOpened) => {
    this.setState(prev => ({ ...prev, wasOpened }));
  }

  render() {
    const { device, isLoading, listing, selectedCarouselItem, show } = this.props;
    return (
      <Modal
        setWasOpened={this.setWasOpened}
        show={this.props.show}
        handleClose={this.props.hideModal}
        device={device}
        showThumbs={true}>
        {this.props.show &&
          <>
            <div data-testid="carousel-display-modal" flex="column justify-center grow" className="carousel-display-wrapper">
              <CarouselDisplayer isModal={true} showThumbs={true} show={show} device={device}
                isLoading={isLoading} listing={listing} selectedItem={selectedCarouselItem}/>
              {device !== 'desktop' && <DetailsContact isLoading={isLoading} listing={listing} disableSellerInfo={true} toggle={true} />}
            </div>
          </>
        }
      </Modal>
    );
  }
}

export default CarouselModal;

import ServerAd from '../../../components/ServerAd';
import classNames from 'classnames';
import React from 'react';
import { matchAdName } from '../../../utils/ads/adsUtils';
import './styles.css';

const CompanionServerAd = ({ ads, adKey }) => {
  return (
    <section className={classNames('companion-ad', { 'sticky': adKey.includes('sticky') })} id={`companion-ad-${adKey}`} data-e2e={`companion-ad-${adKey}`}>
      <ServerAd ad={matchAdName(ads, adKey)} />
    </section>
  );
};

export default CompanionServerAd;

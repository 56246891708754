import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import { Helmet } from 'react-helmet';
import { SeoContent, WordsmithContent } from '@dmm/react-common-components';
import ResultsFooter from '../ResultsFooter';
import './styles.css';
import './seo-content-styles.css';
import './wordsmith-content-styles.css';
import BreadCrumb from '../../../components/BreadCrumb';
import ResultsHeader from '../ResultsHeader';
import MobileSearchFilters from '../MobileSearchFilters';
import RefineSearchSideBar from '../RefineSearchSideBar';
import ListingListV2 from '../../../components/ListingListV2';
import PaymentCalculator from '../../../components/PaymentCalculator';
import LoadingListingListV2 from '../../../components/ListingListV2/LoadingListingListV2';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { adsConfig, getTargeting } from './resources/adsConfig';
import {
  getSRPHeader,
  getSEOH2Header,
  getItemListSchema,
  getAggregateOfferSchema,
  getMetaDescription,
  getPageTypeFromUrl,
  memoizedGetSRPHeaderAndMetaTitleCommonPieces,
  getWordsmithFAQSchema
} from '../../../utils/metaContentHelper';
import { getDefaultParams, parseSearchParams } from '../../../utils/urlHelpers/listings';
import { getBoatUrl } from '../../../utils/urlHelpers/boat';
import { getSearchResultBreadCrumb } from '../../../utils/breadCrumbsHelper';
import { isMobileResolution } from '../../../utils/commonHelper';
import AdProvider from '../../../components/Ads/AdProvider';
import * as utils from '../../../store/utils';
import * as tridentActions from '../../../store/actions/tridentApi';
import * as storage from '../../../utils/storage';
import { REDUCTION_REDIRECT_COOKIE, UNAVAILABILITY_REDIRECT_COOKIE } from '../../../constants/boatDetails';
import DynamicContent from '../../../components/DynamicContent';
import ExpertReviewsTitle from '../../../components/ExpertReviewsTitle';
import RelatedContent from '../../../components/RelatedContent';
import RelatedBoatArticles from '../../../components/RelatedBoatArticles';
import AlertMessage from '../../../components/AlertMessage';
import OemModalContainer from '../../OemModalContainer';
import DealerLocator from '../../DealerLocator';
import BrandedHeader from '../../../components/BrandedHeader';
import { YoutubeVideos } from '@dmm/lib-react-videos';
import '@dmm/lib-react-videos/dist/@dmm/lib-react-videos.min.css';
import classnames from 'classnames';
import OemHeader from '../OemHeader';
import { BOATS_PAGE_SIZE, MANUFACTURER_SRP_SIZE } from '../../../constants/boats';
import '@dmm/lib-react-ui-components/dist/boattrader.css';
import { GetPreQualifiedCollapsible } from '../GetPreQualifiedCollapsible';
import * as aiHelper from '../../../utils/aiHelper';
import { getBrandDetailsData, showOemHeaderForPayingBrandsSRP } from '../../../utils/listingHelper';
import PayingBrandHeader from '../../../components/PayingBrandHeader';
import { setAiEvent } from '../../../store/actions/dataLayer';
import { bindActionCreators } from 'redux';
import OemFooter from '../OemFooter';
import FinanceBanner from '../FinanceBanner';
import {matchAdName} from '../../../utils/ads/adsUtils';
import ServerAd from '../../../components/ServerAd';

class BoatsMainContent extends PureComponent {
  constructor(props) {
    super(props);
    const cookies = get(props, 'cookies', {cookies: {}}).cookies;
    this.state = {
      isMobileResolution: false,
      stickyLeaderBoard: false,
      showOemModal: false,
      showDealerLocator: false,
      window: false,
      isMounted: false,
      aiActive: false,
      aiLoading: false,
      generatedSuggestions: '',
      isSimilarBoatsPage: cookies[UNAVAILABILITY_REDIRECT_COOKIE] || cookies[REDUCTION_REDIRECT_COOKIE]
    };
  }

  handleOemModals = (type, details) => {
    let listingDetails = null;
    let { showDealerLocator, showOemModal } = this.state;
    showDealerLocator = false;
    showOemModal = false;
    switch (type) {
    case 'locator':
      showDealerLocator = true;
      showOemModal = false;
      listingDetails = {
        listing: get(details,'listing')
      };
      break;
    case 'oem':
      showDealerLocator = false;
      showOemModal = true;
      break;
    }
    this.setState({ ...this.state,
      showOemModal,
      showDealerLocator,
      listingDetails
    });
  }

  populateAlertMessage = () => {
    let cookies = get(this.props, 'cookies');
    let mainText;
    let secondaryText;
    let alertMessage;

    if (cookies && cookies.cookies[REDUCTION_REDIRECT_COOKIE] && !get(this.props.params, 'ownerId')) {
      mainText = 'The link you clicked has expired.';
      secondaryText = 'Please see the updated list of boats below.';
      alertMessage = true;
    } else if (cookies && cookies.cookies[UNAVAILABILITY_REDIRECT_COOKIE]) {
      mainText = 'The boat you are searching for is no longer available.';
      secondaryText = 'We are showing you a list of related boats instead.';
      alertMessage = true;
    }
    this.setState({
      alertMainText: mainText,
      alertSecondaryText: secondaryText,
      url: get(this.props, 'location.pathname'),
      showAlertMessage: alertMessage
    });
    cookies && cookies.remove(UNAVAILABILITY_REDIRECT_COOKIE, { path: '/' });
    cookies && cookies.remove(REDUCTION_REDIRECT_COOKIE, { path: '/' });
  }

  resizeHandler = () => {
    this.setState(
      {
        isMobileResolution: isMobileResolution() ? true : false,
      }
    );
  }

  getOffset() {
    const leaderBoard = document.body.querySelector('#div-gpt-mobile-leaderboard-custom');
    if (leaderBoard) {
      return leaderBoard.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
    }
    return 0;
  }

  handleLeaderboard() {
    if (isMobileResolution()) {
      let windowPos = document.documentElement.scrollTop || window.pageYOffset;
      let offset = this.getOffset() || 9;

      if (windowPos > offset + 42) {
        if (!this.state.stickyLeaderBoard) {
          this.updateLeaderboard(true);
        }
      } else if (windowPos < offset - 8) {
        if (this.state.stickyLeaderBoard) {
          this.updateLeaderboard(false);
        }
      }
    }
  }

  updateLeaderboard(sticky) {
    const leaderBoard = document.body.querySelector('#div-gpt-mobile-leaderboard-custom');
    if (leaderBoard) {
      leaderBoard.style.minHeight = sticky ? leaderBoard.children[0] ? leaderBoard.children[0].offsetHeight + 'px' : null : null;
    }
    this.setState({stickyLeaderBoard: sticky});
  }

  saveSearchResults = () => {
    let searchUrl = get(this.props, 'location.pathname', '');
    const nextPreviousData = {};
    nextPreviousData.searchParams = parseSearchParams(searchUrl, undefined, this.props.searchPage);
    nextPreviousData.searchUrl = searchUrl;
    nextPreviousData.pageLow = parseInt(nextPreviousData.searchParams.page);

    let records = get(this.props, 'search.records', []);
    nextPreviousData.urls = records.map((listing) => getBoatUrl(listing));

    let oemRecords = get(this.props, 'oemList.records', []);
    nextPreviousData.oemUrls = oemRecords.map((listing) => getBoatUrl(listing));
    storage.setLocalItem('nextPreviousData', JSON.stringify(nextPreviousData));
    window?.permutiveHelper?.pageView && window.permutiveHelper.pageView();
  }

  componentDidMount() {
    const isAIActive = Boolean(get(this.props, 'cookies')?.cookies?.aiActive);

    window.addEventListener('resize', this.resizeHandler);
    window.addEventListener('scroll', this.handleLeaderboard.bind(this));

    this.resizeHandler();
    this.handleLeaderboard();
    this.populateAlertMessage();

    this.setState({
      isMounted: true,
      window: window,
      aiActive: isAIActive
    });
  }

  componentDidUpdate(prevProps) {
    if (!utils.isServer()) {
      this.saveSearchResults();
    }
    if (this.state.showAlertMessage &&
        get(this.props, 'location.pathname') !== get(prevProps, 'location.pathname')) {
      this.setState({showAlertMessage: false});
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
    window.removeEventListener('scroll', this.handleLeaderboard.bind(this));
  }

  async aiSearch(event) {
    event.preventDefault();

    const search = event.target[0]?.value;

    if (!this.state.aiLoading && search) {
      this.setState({ aiLoading: true, generatedSuggestions: '' });

      try {
        const result = await aiHelper.searchBoats(search);

        this.props.setAiEvent(search, '', 'ai onsite');

        this.props.history.push('/boats' + result.boatTraderUrl);

        this.setState({ aiLoading: false, generatedSuggestions: result.generatedSuggestions });
      } catch (error) {
        this.setState({ aiLoading: false, generatedSuggestions: '' });
      }
    }
  }

  render() {
    const { isMobileResolution, isMounted, stickyLeaderBoard } = this.state;

    const sponsoredAdsParams = {
      adId: 'div-gpt-mobile-leaderboard-custom',
      classes: 'bp2max ad-320x80' + (stickyLeaderBoard ? ' sticky-leaderboard' : '')
    };

    const {
      listing,
      noAdsParam,
      similarBoatsForOEMs,
      partyDetails,
      tridentTeaserRate,
      ficoScores,
      isLeadSubmitted,
      serverAds
    } = this.props;
    const params = get(this.props, 'match.params', {});
    const makeModel = get(this.props, 'makeModel');
    const facets = get(this.props, 'facets', {});
    const defaultParams = getDefaultParams(params);
    const isBranded = !!params.oem && !isEmpty(params.oem);
    const breadCrumbs = getSearchResultBreadCrumb(defaultParams, makeModel, partyDetails, params.seoMakeInfo, null, isBranded, this.props.oemDetails);
    const pos = { page: defaultParams.page, pageSize: defaultParams.pageSize };
    const targeting = getTargeting(getDefaultParams(params), get(this.props, 'sponsored.records', []).length > 0, this.props);
    const SRPHeader = getSRPHeader(params, makeModel, partyDetails);
    const searchCount = get(this.props, 'search.count', 0);
    const pageType = getPageTypeFromUrl(get(this.props, 'location.pathname', ''));
    const metaDescription = getMetaDescription(pageType, params, searchCount, listing, makeModel, undefined, undefined, undefined, this.state.isSimilarBoatsPage);
    const search = get(this.props, 'search', {});
    const minMaxPercentilPrices = get(this.props, 'facets.minMaxPercentilPrices', []);
    const minPrice = find(minMaxPercentilPrices, { value: 'minPrice' }) ? find(minMaxPercentilPrices, { value: 'minPrice' }).count : 0;
    const maxPrice = find(minMaxPercentilPrices, { value: 'maxPrice' }) ? find(minMaxPercentilPrices, { value: 'maxPrice' }).count : 0;
    const itemListSchemaContent = getItemListSchema(params, search, get(this.props, 'location.pathname', ''));
    const itemListSchema = itemListSchemaContent ? <script type="application/ld+json">{JSON.stringify(itemListSchemaContent)}</script> : '';
    const itemAggregateOfferSchema = getAggregateOfferSchema(search, get(this.props, 'location.pathname', ''), makeModel, SRPHeader, metaDescription, minPrice, maxPrice );
    const itemAggregateOffer = itemAggregateOfferSchema ? <script type="application/ld+json">{JSON.stringify(itemAggregateOfferSchema)}</script> : '';
    const currentPage = get(params, 'page', 1);
    const showDealers = this.props.oemDetails && this.props.oemDetails.oemDealerLocator;
    const secondaryLocalities = this.props.secondaryLocalities && this.props.secondaryLocalities.join(', ');
    const dealerId = get(params, 'ownerId');
    const videos = get(this.props, 'videos', []);
    const relatedBoatArticles = get(this.props, 'relatedBoatArticles', []);
    const showOemFooter = isBranded && this.props.oemDetails?.name;
    const isFirstPage = (pos.page === '1');
    const { expertReviewsTitle } = memoizedGetSRPHeaderAndMetaTitleCommonPieces(params, false, makeModel, partyDetails);
    const displayExpertReviewsTitle =
      !this.props.isWorking && (videos?.length > 0 || relatedBoatArticles?.length > 0) && isFirstPage;
    const wordsmithData = getWordsmithFAQSchema(this.props.wordsmithContent);
    const wordsmithFAQSchema = wordsmithData.FAQSchema ? <script type="application/ld+json">{wordsmithData.FAQSchema.replace(/(\n|<p>|<\/p>)/g, '')}</script> : '';
    let topSponsoredListings = get(this.props, 'sponsored.records', []);
    let carouselSponsoredListings = get(this.props, 'carouselSponsored.records', []);
    let listings = get(this.props, 'search.records', []);
    let listingLoaderCount = BOATS_PAGE_SIZE;
    let manufacturerLoaderCount = MANUFACTURER_SRP_SIZE;
    const brandInformation = getBrandDetailsData(this.props.oemDetails, this.props.brandDetails, this.props.searchPage);
    const showHeaderPayingBrands = showOemHeaderForPayingBrandsSRP(currentPage, makeModel, brandInformation, get(this.props, 'location.pathname', ''));
    const useServerAds = this.props.serverAds && this.props.serverAds.length;
    return (
      <div>
        <Helmet>
          {itemListSchema}
          {itemAggregateOffer}
          {wordsmithFAQSchema}
        </Helmet>
        {showHeaderPayingBrands && <PayingBrandHeader showHeaderPayingBrands={showHeaderPayingBrands} oemInformation={brandInformation}/>}
        <AdProvider url={get(this.props, 'location.pathname', '')} isWorking={this.props.isWorking} targeting={targeting} adsConfig={adsConfig} noAdsParam={noAdsParam} retargetOnAdRefresh={true}>
          <div className={classnames('content-head')}>
            <BreadCrumb items={breadCrumbs.breadCrumbs} payingBrandSRP={showHeaderPayingBrands}/>
            {currentPage === 1 && dealerId
              ?  <BrandedHeader partyDetails={partyDetails} brandedHeaderH1={SRPHeader} />
              : isMobileResolution ? !isBranded && <h1 data-e2e="srp-header" data-test-sp="srp-header-h1">{SRPHeader}</h1>
                : !isBranded && <h1 data-e2e="srp-header" data-test-sp="srp-header-h1">{SRPHeader}</h1>
            }
            {!dealerId && secondaryLocalities && <p>{secondaryLocalities}</p>}
          </div>
          <SeoContent position="top" isFirstPage={isFirstPage} content={this.props.interestingInformation}/>

          { isBranded &&
            <OemHeader isMobileResolution={isMobileResolution}
              oemDetails={this.props.oemDetails} page={pos.page}
              onContact={type => this.handleOemModals(type)}
              serverAds={serverAds}
              params={this.props.params}
            />
          }

          <div data-e2e="main-content-container" id="main-content" className={classnames({ branded: isBranded})}>
            {showDealers && <DealerLocator
              device={this.state.isMobileResolution ? 'mobile' : 'desktop'}
              hideModal={this.handleOemModals}
              isOpen={this.state.showDealerLocator}
              oemDetails={this.props.oemDetails}
              isWorking={this.props.isWorking}
              listing={get(this.state, 'listingDetails.listing', null)}
            /> }
            {this.state.showOemModal && <OemModalContainer
              prePopulatedText={get(this.state, 'listingDetails.prePopulatedText' ,null )}
              oemDetails={this.props.oemDetails}
              onClose={() => this.handleOemModals()}
              isMobile={this.state.isMobileResolution}
              listing={get(this.state, 'listingDetails.listing', null)}
            />}

            { !isMobileResolution &&
                <div data-e2e="left-content-container"  id="left-content">
                  <RefineSearchSideBar {...this.props}/>
                  {isMounted  &&
                    <>
                      <GetPreQualifiedCollapsible listing={this.props.listing} isLeadSubmitted={isLeadSubmitted } ficoScores={ficoScores} isMobile={false} isBranded={isBranded}/>
                      <PaymentCalculator
                        showMonthlyCalculatorOnly
                        tridentTeaserRate={tridentTeaserRate}
                        tridentTermInMonths={240}
                        showPreQualified={false}
                        utmCampaign="SRPcalculatorapply"
                        utmContent="SRPcalculatorapply"
                        useAsCard
                        isBranded={isBranded}
                        isSRP
                      />
                    </>
                  }
                </div>
            }
            <div data-e2e="right-content-container" id="right-content">
              {this.state.aiActive && <form id="ai-search" onSubmit={(event) => this.aiSearch(event)}>
                <input type="text" aria-label="ai-search" placeholder="Search BoatTrader.com" />
                <button type="submit" disabled={this.state.aiLoading}>
                  {!this.state.aiLoading ? 'Search' : 'Searching...'}
                </button>
                {(this.state.generatedSuggestions || this.state.aiLoading) && <div className="ai-suggestions">
                  <img src="https://www.boattrader.com/static/app/boattrader-icon.png" alt="logo" />
                  {!this.state.generatedSuggestions && <div className="ai-loading"></div>}
                  {this.state.generatedSuggestions && <p>{this.state.generatedSuggestions}</p>}
                </div>}
              </form>}

              { useServerAds && !isBranded ? <ServerAd
                ad={matchAdName(this.props.serverAds, 'boat-search_banner-top')}
                adName="boat-search_banner-top"
                className="server-ad"
              /> : <FinanceBanner /> }

              <ResultsHeader {...this.props} isMobile={isMobileResolution} />
              <div data-e2e="search-results-container" className={classnames('search-results')}>
                { this.state.showAlertMessage &&
                  <AlertMessage
                    mainMessage={this.state.alertMainText}
                    secondaryMessage={this.state.alertSecondaryText} />
                }
                { similarBoatsForOEMs &&
                    <AlertMessage
                      mainMessage="It looks like we do not currently have any matching listings."
                      secondaryMessage="Here are a few listings we think you might like based on your search." />
                }
                <section data-e2e="boat-listings-container" className={classnames('boat-listings', 'available-banner')}>
                  {
                    !this.props.isWorking && this.props.search && this.props.search.records ?
                      <ListingListV2
                        noAdsParam={noAdsParam}
                        sponsoredAdsParams={sponsoredAdsParams}
                        listings={listings}
                        myboats={this.props.myboats}
                        cookies={this.props.cookies}
                        history={this.props.history}
                        url={this.props.location.pathname}
                        pos={pos}
                        isBrandedSrp={isBranded}
                        dealerId={dealerId}
                        tridentTeaserRate={this.props.tridentTeaserRate}
                        oemList={this.props.oemList}
                        oemDetails={this.props.oemDetails}
                        numSponsoredListings={topSponsoredListings.length}
                        carouselSponsoredListings={carouselSponsoredListings}
                        isPayingBrand={showHeaderPayingBrands}
                        serverAds={this.props.serverAds}
                      />
                      : <LoadingListingListV2 number={isBranded ? listingLoaderCount : listingLoaderCount + manufacturerLoaderCount}/>
                  }
                  <ResultsFooter {...this.props} />
                </section>
              </div>
              <SeoContent title={getSEOH2Header(params, makeModel)} position="bottom" isFirstPage={isFirstPage} content={this.props.interestingInformation}/>
              { displayExpertReviewsTitle && <ExpertReviewsTitle title={expertReviewsTitle} /> }
              {
                videos && videos.length > 0 && isFirstPage &&
                <div data-test-sp="srp-related-articles-videos">
                  <YoutubeVideos
                    videos={videos}
                    device={this.state.isMobileResolution ? 'mobile' : 'desktop'}
                  />
                </div>
              }
              <RelatedBoatArticles
                isFirstPage={isFirstPage}
                isLoading={this.props.isWorking}
                relatedBoatArticles={relatedBoatArticles}
              />
              <WordsmithContent
                isFirstPage={isFirstPage}
                content={wordsmithData.narrative}
                hasMoreThanOneListing={searchCount > 1}
              />
              <RelatedContent params={params} facets={facets} />
              { isMobileResolution &&
                <MobileSearchFilters {...this.props}/>
              }
              { searchCount > 1 && !similarBoatsForOEMs &&
                <DynamicContent params={params} facets={facets} count={this.props.search.count}/>
              }
              { isMobileResolution && (
                <>
                  <GetPreQualifiedCollapsible isLeadSubmitted={isLeadSubmitted} ficoScores={ficoScores} isMobile={true}/>
                  <PaymentCalculator
                    showMonthlyCalculatorOnly
                    tridentTeaserRate={tridentTeaserRate}
                    tridentTermInMonths={240}
                    initialState="closed"
                    showPreQualified={false}
                    utmCampaign="SRPcalculatorapply"
                    utmContent="SRPcalculatorapply"
                    useAsCard
                    isSRP
                  />
                  <div className="calc-disclosure mobile">{`* Estimated monthly payment based on a 240-month loan at ${tridentTeaserRate} APR.`}</div>
                </>
              )}
            </div>
          </div>
          {showOemFooter && <OemFooter oemDetails={this.props.oemDetails}/>}
        </AdProvider>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const teaserRateWithPercent = get(state.app, 'trident.rates[0].teaserRate');
  const teaserRate = teaserRateWithPercent && parseFloat(teaserRateWithPercent.replace(/[%, ]/g, ''));

  return {
    tridentTeaserRate: teaserRate
  };
};

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ setAiEvent }, dispatch),
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(frontloadConnect(({ tridentTeaserRate, dispatch }) => {
  let result;
  if (!tridentTeaserRate && utils.isServer()) {
    result = dispatch(tridentActions.getRatesFromTridentAPI());
  }
  return result;
}, {
  onUpdate: true,
  onMount: true
})(BoatsMainContent));

import React, { useState, useRef, useEffect } from 'react';

export const AccordionQuestion = ({ title, children, open }) => {
  const [isOpen, setIsOpen] = useState(open);
  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = isOpen ? `${contentRef.current.scrollHeight}px` : '0';
  }, [isOpen]);

  return (
    <div className="accordion">
      <button onClick={() => setIsOpen(!isOpen)} className={`${isOpen ? 'active' : ''}`}>
        <h3>{title}</h3>
        <div>
          <svg className={`accordion-arrow ${isOpen ? 'open' : ''}`} width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.16667 15.0832L7.25 7.99984L0.16667 0.916504V15.0832Z" fill="#07324f"/>
          </svg>
        </div>
      </button>
      <div ref={contentRef} data-testid="accordion-question-content">{children}</div>
    </div>
  );
};

import React from 'react';
import PropTypes from 'prop-types';

import './styles.css';
import { ApplyNowButton } from '../ApplyNowButton';
import TrustBox from '../TrustBox';
import { TRACKING_BOAT_LOANS_HERO_CLICK_ACTION_LABEL } from '../../../../constants/BoatLoansV2';

export const Hero = ({ backgroundImageUrl, dataTestId = 'hero-section-component' }) => {
  return (
    <div data-e2e="boat-loans-title-hero-section-container" className="hero-section-container" data-testid={dataTestId}>
      <img className="hero-section-image" alt="Boat Trader Hero Image" src={ backgroundImageUrl }></img>
      <div data-e2e="boat-loans-hero-section" className="hero-section">
        <div data-e2e="boat-loans-flex-item-center" className="flex items-center">
          <div data-e2e="boat-loans-content" className="hero-content">
            <h1 data-e2e="boat-loans-title" className="hero-title">
              Boat Loans
            </h1>
            <p className="hero-subtitle">
              Fast and easy approval! We’ll compare over 15 marine lenders to get you the best rate with the best terms.
            </p>
            <TrustBox
              locale="en-US"
              templateId="5419b732fbfb950b10de65e5"
              height="24px"
              width="100%"
              theme="light"
              color="#616161"
            />
            <ApplyNowButton
              text="Get Pre-Qualified"
              type="button"
              utmCampaign="btboatloanspage"
              utmContent="btboatloanspage"
              data-reporting-click-internal-link-id="hero - apply online in 5 minutes"
              data-reporting-click-internal-link-type="click"
              trackingEvent={{
                'action_label': TRACKING_BOAT_LOANS_HERO_CLICK_ACTION_LABEL
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
};

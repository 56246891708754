import React from 'react';
import './styles.css';
import { Calculator } from '../Calculator';

export const RatesCalculatorSection = ({ dataTestId = 'rates-calculator-section-component' }) => {
  return (
    <div  className="rates-calculator-container" data-testid={dataTestId}>
      <div className="rates-calculator-content">
        <h2 className="title">
          Boat Loan Interest Rates
        </h2>
        <p className="info-text">
          Purchasing a boat is a significant investment, and understanding boat loan interest rates and financing options is crucial for making a financially sound decision.
          Like any other loan, the interest rate you’ll be offered on a boat loan depends on multiple factors, including your credit score, the amount you plan to borrow, and
          the loan&apos;s term length.
        </p>
      </div>

      <Calculator />
    </div>
  );
};

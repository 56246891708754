import ServerAd from '../../../components/ServerAd';
import React from 'react';

const HeroServerAd = ({ad}) => {
  return (
    <>
      { (ad && Object.keys(ad).length) && <ServerAd ad={{...ad}} /> }
    </>
  );
};

export default HeroServerAd;

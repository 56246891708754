import React, {useContext, useRef, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import {BDPContext} from '../MainContent/BoatDetailsPage';
import CollapsibleDetailsSection from '../DetailsSection/CollapsibleDetailsSection';
import { BoatDetails } from '@dmm/lib-react-ui-components';
import { getBoatDetails } from '../../../utils/listingHelper';
import BDPEmailLeadForm from './BDPEmailLeadForm';
import useIntersectionObserver from '../../../utils/hooks/useIntersectionObserver/useIntersectionObserver';
import { isFSBOorOEMListing } from '../../../utils/listingHelper';
import { showAdditionalLeadsModal } from '../../../store/actions';
import { useFeatureFlags } from '../../../utils/experiment/kameleoonHelper';
import FinanceableBoatSummary from '../FinanceableBoatSummary';

const BDPDetailsSection = ({
  isWorking, listing, myboats, cookies, url, detailsState,
  adParams, onClickRequestInformation, breakpoint,
  displayModalPreQualified, shouldShowFinanceSummary, onFormVisibilityChange,
  openModalPostLead,
  setDisableRequestInfo,
  userRef,
  userEmail,
  fadeButtons,
  serverAds,
  useServerAds
}) => {
  const { featureFlagBDPPhase4 } = useFeatureFlags();
  const {tridentValues, isLeadSubmitted} = useContext(BDPContext);
  const {tridentTermInMonths, tridentLoanAmount, tridentTeaserRate} = tridentValues;

  const leadRef = useRef(null);
  const dispatch = useDispatch();
  const isFormVisible = useIntersectionObserver(leadRef, { threshold: 0.5 });

  useEffect(() => {
    if (onFormVisibilityChange) {
      onFormVisibilityChange(isFormVisible);
    }
  }, [isFormVisible, onFormVisibilityChange]);

  const isFSBOorOEM = isFSBOorOEMListing(listing);

  return (
    <div className="collapsible-details-section">
      <div className="additional-boat-details">
        <BoatDetails details={getBoatDetails(listing)} />
      </div>

      {breakpoint !== 'desktop' && !isFSBOorOEM && featureFlagBDPPhase4 &&
        <div className="email-lead-form-container" ref={leadRef}>
          <BDPEmailLeadForm
            listing={listing}
            breakpoint={breakpoint}
            buttonAttributes={{ 'data-testing-class': `form-submission-${breakpoint}-bdp` }}
            onSuccess={(ld) => {
              dispatch(showAdditionalLeadsModal(ld));
            }}
          />
        </div>
      }

      {(shouldShowFinanceSummary && breakpoint !== 'desktop') &&
          <FinanceableBoatSummary
            listing={listing}
            isMobile={true}
            openModalPostLead={openModalPostLead}
            userRef={userRef}
            userEmail={userEmail}
            breakpoint={breakpoint}
            setDisableRequestInfo={setDisableRequestInfo}
            fadeButtons={fadeButtons}
          />
      }


      <CollapsibleDetailsSection
        url={url}
        myboats={myboats}
        cookies={cookies}
        listing={listing}
        adParams={adParams}
        isLoading={isWorking}
        breakpoint={breakpoint}
        isLeadSubmitted={isLeadSubmitted}
        tridentLoanAmount={tridentLoanAmount}
        tridentTeaserRate={tridentTeaserRate}
        tridentTermInMonths={tridentTermInMonths}
        showModalPreQualified={displayModalPreQualified}
        onClickRequestInformation={onClickRequestInformation}
        openPaymentCounter={detailsState.openPaymentCounter}
        openPaymentSection={detailsState.openPaymentSection}
        isMobile={breakpoint !== 'desktop'}
        serverAds={serverAds}
        useServerAds={useServerAds}
      />
    </div>
  );
};

export default BDPDetailsSection;

import React from 'react';
import { AccordionQuestion } from './AccordionQuestion';
import './styles.css';
import { FAQ_ACCORDION_CONTENT } from './faqContent';
import { ApplyNowButton } from '../ApplyNowButton';

export const FrequentlyQuestionsSection = () => {

  const faqContent = FAQ_ACCORDION_CONTENT;
  const half = Math.ceil(faqContent.length / 2);
  const firstColumn = faqContent.slice(0, half);
  const secondColumn = faqContent.slice(half);


  return (
    <div className="boat-loans-rates-frequently-questions-section">
      <div className="rates-faq-content" >
        <h2>Frequently Asked Questions About Boat Loan Interest Rates</h2>
        <div className="content">
          <div className="col">
            {firstColumn.map((item, i) => (
              <AccordionQuestion key={`firstCol-${i}`} title={item.question} open={i === 0}>
                <p>{item.answer}</p>
              </AccordionQuestion>
            ))}
          </div>
          <div className="col">
            {secondColumn.map((item, i) => (
              <AccordionQuestion key={`secondCol-${i}`} title={item.question} open={i === 0}>
                <p>{item.answer}</p>
              </AccordionQuestion>
            ))}
          </div>
        </div>
        <div className="button-container">
          <ApplyNowButton
            text="Get Pre-Qualified"
            type="button"
            utmCampaign="bt-rates-calc-pg"
            utmContent="bt-rates-calc-pg"
            trackingEvent={{
              'action_label': 'footer faq interest rates'
            }}
          />
        </div>
      </div>
    </div>
  );
};

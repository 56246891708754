import React from 'react';
import MainContent from './MainContent';
import {connect} from 'react-redux';
import get from 'lodash/get';
import {frontloadConnect} from 'react-frontload';
import {getParamsFromLocationAndHistoryQueryString} from '../../utils/featureToggles';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';
import LoadableNotFoundPage from '../NotFoundPage';
import {withABTest} from '../../server/abTest/withABTest';
import {withCookies} from 'react-cookie';

const SocialBrowsePage = (props) => {
  return !props.success
    ? <LoadableNotFoundPage/>
    : <MainContent {...props} pageType="SocialBrowsePage"/>;
};

const mapStateToProps = (state) => ({
  sbpListings: get(state.app, 'data.sbpListings', []),
  success: get(state.app, 'success', '')
});

const frontloadConnected = frontloadConnect(async ({dispatch, history, location, cookies, abTestConfiguration}) => {
  const params = getParamsFromLocationAndHistoryQueryString({
    location,
    history,
  });

  const pathname = get(history, 'location.pathname', '');
  const imtID = pathname.replace(/\D/g, '');

  if (utils.isServer()) {
    await dispatch(actions.getSbpData(params, imtID, abTestConfiguration, cookies.cookies));
  }
}, {
  onMount: true,
  onUpdate: true
})(SocialBrowsePage);

export default withABTest(withCookies(connect(mapStateToProps)(frontloadConnected)));

import React from 'react';
import { connect } from 'react-redux';
import {withCookies} from 'react-cookie';
import { frontloadConnect } from 'react-frontload';
import get from 'lodash/get';
import * as utils from '../../store/utils';
import { getParamsFromLocationAndHistoryQueryString } from '../../utils/featureToggles';
import * as actions from '../../store/actions';

import DefaultLayout from '../../layouts/Default';
import MainContent from './MainContent';

const Home = (props) => {
  return (
    <>
      <DefaultLayout {...props} pageType="Home">
        <MainContent {...props} />
      </DefaultLayout>
    </>
  );
};

const mapStateToProps = state => ({
  home: get(state.app, 'data', {}),
  facets: get(state.app, 'data.facets', {}),
});

const frontloadConnected = frontloadConnect(async ({ dispatch, location, cookies }) => {
  const params = getParamsFromLocationAndHistoryQueryString({
    location
  });

  if (utils.isServer()) {
    await dispatch(actions.getHomeData(params, cookies?.cookies));
    await dispatch(actions.getFacets());
  }

  if (actions.shouldGetData(location)) {
    await dispatch(actions.getHomeData(params, cookies?.cookies));
    await dispatch(actions.getFacets());
  }
}, {
  onMount: true,
  onUpdate: true
})(Home);

export default withCookies(connect(mapStateToProps)(frontloadConnected));
